
.date-picker {
  font-family: 'Inter', sans-serif;

  .modal-dialog {
    max-width: 400px;
    margin: 16px auto;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }

  .modal-body {
    padding: 28px;

    .rdrDateDisplayWrapper {
      background-color: transparent;
    }

    .rdrDateDisplay {
      margin: 0 0.833em;
    }

    .rdrDateDisplayItem {
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.1);

      input {
        color: #616161;
      }
    }

    .rdrMonthsVertical {
      align-items: center;
    }

    .rdrDateDisplayItemActive {
      border-color: rgba(52, 94, 79, 0.6);
    }

    .rdrDay.rdrDayPassive, .rdrDay.rdrDayDisabled {
      background-color: transparent;
    }
    .rdrDay:not(.rdrDayPassive) .rdrDayNumber > span {
        color: #171717 !important;
    }
    .rdrDay.rdrDayPassive .rdrDayNumber > span {
      color: #bcc7cd !important; /* #96a8b2, #8a9da8 */
    }
    .rdrDay.rdrDayDisabled .rdrDayNumber > span {
      color: #bcc7cd !important; /* #96a8b2, #8a9da8 */
      text-decoration: line-through;
    }
    .rdrDay.rdrDayToday .rdrDayNumber {
      span {
        font-weight: 400;
      }

      span:after {
        display: none;
      }
    }

    .rdrDayInPreview, .rdrDayEndPreview, .rdrDayStartPreview {
      border-color: rgba(52, 94, 79, 0.6) !important;
    }
  }
}

@media (max-width: 432px) {
  .date-picker .modal-dialog {
    margin: 0 16px;
  }
}