.pac-container {
  font-family: 'Inter', sans-serif;
  border-radius: 7px;
  box-shadow: none;
  width: auto !important;
  margin-left: -26px;
  margin-top: 5px;
  border: 1px solid rgba(35, 35, 35, 0.09);
}

.pac-container::after {
  display: none;
}

.pac-item {
  color: #171717;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  padding: 8px 16px;
  border-top: none;
  min-width: 219px;
}

.pac-item-query {
  color: #171717;
  font-weight: 400;
}

.pac-matched {
  color: #171717;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .pac-container {
    margin-left: -20px;
  }

  .pac-item {
    min-width: 205px;
  }
}

@media (max-width: 800px) {
  .pac-container {
    margin-left: -12px;
    margin-top: 2px;
  }
}
