/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

/* Sign Up and Login Styles*/
.kep-login-facebook {
  margin: auto;
  border-radius: 10px !important;
  right: 0;
  margin-top: 1.5em;
  height: 40px;
  padding: 0 !important;
  justify-content: center;
  align-self: center;
  margin-top: 1em;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.facebook_style_button {
  margin: auto;
  right: 0;
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: center;
  align-self: center;
  margin-top: 1em;
  margin-bottom: 2.1rem;
}

.facebook_style_button > span > button {
  border-radius: 10px !important;
  height: 40px;
  width: 100%;
  border: 1px solid rgb(239, 239, 239) !important;
}

.float-button-container > span {
  padding-bottom: 1.5rem;
}

.float-button-container > span > button {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.google_style_button {

  width: 50%;
  padding: 0 !important;
  justify-content: center;
  align-self: center;

  
}

.google_style_button > button {
  border-radius: 10px !important;
  height: 40px;
  width: 100%;
  border: 1px solid rgb(239, 239, 239) !important;

}

.google_style_button > button > span {
  display: block !important;
  margin: auto;
  right: 0;
  font-size: 1.6rem;
}
.float-button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#eventTable {
  margin-bottom: 10rem;
}

/* HostDashboard Styles*/

.Host_cards {
  display: flex;
  width: 100%;
}

.flex-item-img {
  padding: 10px;
  flex-basis: 25%;
}

.flex-item-data {
  padding: 10px;
  flex-basis: 50%;
}

.flex-item-button {
  padding: 10px;
  flex-basis: 25%;
}

@media (max-width: 800px) {
  .kep-login-facebook{
    width:100%;
  }

  .float-button-container {
    flex-direction: column;
  }
 
  .Host_cards {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    flex-grow:1 ;
  }
  .google_style_button > button {
    height:100%;
  }
  .google_style_button  {
    width:100%;
    margin-bottom: 1rem;
  }
  .facebook_style_button{
    width:100%;
    padding:0;
    margin-top: 0;
  }

  .facebook_style_button > span > button {
   height:100%;
  }

  .flex-item-img, .flex-item-data, .flex-item-button {
    flex-basis: 100%;
    padding:5px;
    align-items: center;
    justify-content: center;
  }

  .text_center_style
  {
    text-align: center;
  }
}

