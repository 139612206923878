
.auth-modal {
  font-family: 'Inter', sans-serif;

  .modal-dialog {
    max-width: 400px;
    margin: 16px auto;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }

  .modal-body {
    padding: 28px;
  }
}

@media (max-width: 432px) {
  .auth-modal .modal-dialog {
    margin: 16px;
  }
}