@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&family=Noto+Serif:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;

}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Montserrat", Arial, Helvetica Neue, sans-serif;
  color: #002c3e;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  color: #136794;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.modal{
  padding-left: 0 !important;
}
.venue-modal{
  border-radius: 10px;
}
.venue-modal .modal-body{
  padding:2rem;
}

.dialog-modal{
  margin:auto;
}

@media screen and (min-width: 1000px) {
    .venue-modal {
      display: none;
    }
}
@media screen and (max-width: 500px) {
  .venue-modal .modal-body{
    width:98vw;

  }
}

.carousel {
  width: 100%;
}